import {Controller} from "stimulus"
import $ from 'jquery';



export default class extends Controller {

    connect() {
        let div = $('.section-background');
        if(div.length > 0){
            $.ajax({
                url: location.origin + `/${sessionStorage.getItem('locale')}/preferences/` + div[0].dataset.preference + '.json',
                data: {
                },
                type: 'GET',
                success: function (data) {
                    if (data.has_image) {
                        div.css({
                            'background-image': 'url("' + data.background_image + '")'
                        })
                    } else {
                        div[0].classList.add('change-background');
                    }

                    // unblockBody();
                }
            })
        }else{
            // unblockBody();
        }


    }
}