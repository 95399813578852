import {Controller} from "stimulus"
import $ from 'jquery';
import toastr from 'toastr/toastr'
import bootbox from 'bootbox/bootbox';
import 'toastr/toastr.scss'

require('leaflet/dist/leaflet.css')
import 'leaflet'
import {OpenStreetMapProvider} from 'leaflet-geosearch'
const provider = new OpenStreetMapProvider();

//Variables
let map = '';
let edit_map = '';
// let latlngs = [[23.916, -73.537],
//     [19.421, -86.155]];
let latlngs = [[24.000, -73.619],
    [19.300, -85.590]];


function getPointData(lat, lng, latlng, addrs = null,popup = null) {
    var reversegeocode = 'https://nominatim.openstreetmap.org/reverse?format=json&lat='+lat+'&lon='+lng+'&zoom=18&addressdetails=1'; //alert(reversegeocode);
    $.getJSON(reversegeocode, function(data) {
        var msg = `${data.display_name} <br> <span class="helper_text small">Esta dirección es autogenerada, revise el campo de dirección en el formulario para verificar que sea la correcta.</span>`;
        if(addrs !== null){
            addrs.value = data.display_name
        }

        if(popup !== null){
            popup
                .setLatLng(latlng)
                .setContent(msg)
                .openOn(edit_map);
        }


    });
}

export default class extends Controller {
    initialize() {
        let vmap = $('#map');
        if (vmap[0] !== undefined) {

            $.ajax({
                url: `${location.origin}/es/admin/offices/map`,
                data: {
                    format: 'json'
                },
                type: 'GET',
                beforeSend: function () {
                    $('#overlay_map_load').html('<h1>Cargando datos... <span class="fa fa-spin fa-spinner"></span></h1>').delay(5000).fadeOut(1000)
                },
                success: function (data) {
                    if (data.total > 0) {
                        let offices = data.rows;
                        //Inicializando mapa
                        map = L.map('map', {
                            zoom: 6.50,
                            minZoom: 6.50,
                            zoomSnap: 0.25,
                            scrollWheelZoom: true,
                            touchZoom: true
                        });
                        map.fitBounds(latlngs);
                        //FUNCTIONS
                        map.on('click', function (event) {
                            $('#info_box').html(`
                                 <span><strong>Longitud:</strong> ${event.latlng.lng}</span><br>
                                 <span><strong>Latitud:</strong> ${event.latlng.lat}</span><br>
                                 ${event.latlng.lat},${event.latlng.lng}
                                `)
                        })
                        //Agregando capa OpenStreetMap
                        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                            attribution: '&copy; LaberintoTech ',
                            maxZoom: 15
                        }).addTo(map);

                        //Control de escala
                        L.control.scale().addTo(map);

                        //MARCADORES E ICONOS
                        var myIcon = L.icon({
                            iconUrl: `${data.pin}`,
                            className: 'pin-map',
                            popupAnchor: [-3, -3],
                            iconSize: [20, 30]
                        });


                        offices.map(elem => {
                            if (elem.latitude !== "0.0" && elem.longitude !== "0.0") {
                                let open_time = `${elem.open_time}am - ${elem.close_time}pm`;

                                if (elem.full_time) {
                                    open_time = '24 horas';
                                }

                                L.marker([parseFloat(elem.latitude), parseFloat(elem.longitude)], {
                                    draggable: false,
                                    icon: myIcon,
                                    title: `${elem.name} | Click para ver más detalles...`,
                                    alt: ''
                                }).addTo(map).bindPopup(`<h3>${elem.name}</h3>
                                <br><strong>Telefono:</strong> ${elem.phones}
                                <br><strong>Horario:</strong> ${open_time}
                                <br><strong>Dirección:</strong> ${elem.address}`);
                            }
                        })

                    }else{
                        let container_map = document.getElementsByClassName('presentation-with-map');
                        let map_id = $('#map')[0];
                        if(document.getElementById(`to_section_${map_id.dataset.id}`) !== null){
                            document.getElementById(`to_section_${map_id.dataset.id}`).style.display = 'none';
                        }
                        if(container_map !== null && container_map !== undefined){

                            container_map[0].classList.add('ocultar');
                        }
                    }
                }
            })


        }

        //EDIT MAP
        if ($('#edit_map')[0] !== undefined) {
            let lat = document.getElementById('latitude');
            let lng = document.getElementById('longitude');
            let addrs = document.getElementById('address');

            edit_map = L.map('edit_map', {
                zoom: 6.50,
                minZoom: 6.50,
                zoomSnap: 0.25,
                scrollWheelZoom: true,
                touchZoom: true
            });

            if ((lat !== null && lng !== null) && (lat.value !== "0.0" && lng.value !== "0.0")) {
                edit_map.setView([parseFloat(lat.value), parseFloat(lng.value)], 15);

                //MARCADORES E ICONOS
                var myIcon = L.icon({
                    iconUrl: '/pin.png',
                    popupAnchor: [-3, -3],
                    iconSize: [20, 30],
                });

                L.marker([parseFloat(lat.value), parseFloat(lng.value)], {
                    draggable: false,
                    icon: myIcon,
                    title: 'Click para ver más detalles...',
                    alt: ''
                }).addTo(edit_map).bindPopup(`<h3>${document.getElementById('name').value}</h3>
                    <br><strong>Telefono:</strong> ${document.getElementById('phones').value}
                    <br><strong>Horario:</strong> ${document.getElementById('open_time').value}
                    <br><strong>Dirección:</strong> ${document.getElementById('address').value}`);
            } else {
                edit_map.fitBounds(latlngs)
            }


            //Agregando capa OpenStreetMap
            L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://cloudmade.com">CloudMade</a>',
                maxZoom: 24
            }).addTo(edit_map);

            // popup para la direccion
            var popup = L.popup();

            edit_map.on('click', function (event) {
                lat.value = event.latlng.lat;
                lng.value = event.latlng.lng;
                getPointData(event.latlng.lat,event.latlng.lng,event.latlng, addrs, popup);

            })

            // addrs.addEventListener('keyup', async function (e) {
            //     const results = await provider.search({ query: e.target.value });
            // })


            lat.addEventListener('keyup', function (e) {
                if((e.target.value !== null && lng !== null) && (e.target.value !== "0.0" && lng.value !== "0.0")){
                   if(!e.target.value.isEmpty() && !lng.value.isEmpty()){
                       edit_map.setView([parseFloat(e.target.value), parseFloat(lng.value)], 15);

                       getPointData(e.target.value, lng.value,{lat: e.target.value, lng: lng.value}, addrs, popup)
                   }
                }
            })

            lng.addEventListener('keyup', function (e) {
                if((e.target.value !== null && lat !== null) && (e.target.value !== "0.0" && lat.value !== "0.0")){
                    if(!e.target.value.isEmpty() && !lat.value.isEmpty()){
                        edit_map.setView([parseFloat(lat.value), parseFloat(e.target.value)], 15);

                        getPointData(lat.value,e.target.value,{lat: lat.value, lng: e.target.value}, addrs, popup)
                    }
                }
            })
        }
    }

    connect() {

    }

}