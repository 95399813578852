import consumer from "./consumer"
import bootbox from 'bootbox/bootbox';

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}



document.addEventListener('turbo:load', () => {

    if (location.pathname.includes('commercial/rooms/')) {
        const room_element = document.getElementById('room_id')
        const room_id = Number(room_element.getAttribute('data-room-id'))
        const messageContainer = document.getElementById('message_container')
        const user_element = document.getElementById('user_id')
        const user_id = Number(user_element.getAttribute('data-user-id'))
        const is_commercial = (user_element.getAttribute('data-commercial') === 'true');
        const commercial_id = Number(user_element.getAttribute('data-commercial-id'))
        const room_message_message = document.getElementById('room_message_message')
        const chat_status = document.getElementById('chat_status')
        const reciever_id = document.getElementById('room_message_reciever_id').value

        //FORMULARIO DE ENVIO DE MENSAJES
        const form = document.getElementById('formMessage')
        const input_message = document.getElementById('room_message_message')
        const submitMessageBtn = document.getElementById('submitMessageBtn')

        let timeout;
        sessionStorage.setItem('user_id', user_id);
        sessionStorage.setItem('is_commercial', is_commercial);
        sessionStorage.setItem('receiver_id', commercial_id);
        sessionStorage.setItem('chat_room', room_id);


        const channel_room = consumer.subscriptions.create({channel: "RoomChannel", room_id: room_id}, {
            connected() {

                //AL iniciar ubicar el area de mensajes en el ultimo enviado si es que existe
                messageContainer.scrollTo(0, messageContainer.scrollHeight);
                //Al presionar cualquier tecla, notificar al otro usuario que se esta ecribiendo un mensaje
                room_message_message.addEventListener('keydown', function (e) {
                    if (e.key === "Enter") {
                        if (!input_message.value.isEmpty()) {
                            submitMessageBtn.dispatchEvent(new Event('click'));
                        } else {
                            e.stopPropagation();
                            e.preventDefault();

                        }

                    }

                    if (e.key !== "Enter" || e.key !== "Alt" || e.key !== "Shift" || e.key !== "Backspace") {
                        channel_room.send({
                            type: 'typing',
                            message: 'Escribiendo',
                            room_id: room_id,
                            from: user_id,
                            to: reciever_id,
                            code: e.key
                        })
                    }


                })

                // Called when the subscription is ready for use on the server

                //PARA SOLICITAR DATOS DE USUARIO Y TERMINAR LA CONVERSACION
                document.getElementById('get_info_user').addEventListener('click',function () {
                    channel_room.send({
                        type: 'request_info',
                        room_id: Number(room_id),
                        from: user_id,
                        to: reciever_id
                    })
                })

                document.getElementById('terminate_assistance').addEventListener('click', function () {
                    channel_room.send({
                        type: 'terminate',
                        room_id: Number(room_id),
                        from: user_id,
                        to: reciever_id
                    })
                })
            },

            disconnected() {
                    consumer.disconnect();
            },

            received(data) {
                // Called when there's incoming data on the websocket for this channel

                switch (data.type) {
                    case 'connecting':
                        if (parseInt(user_id) === parseInt(data.to)){
                            let indicator = document.getElementById('user_status');
                            let text = document.getElementById('user_status_text');
                            let buttons =  document.getElementById('button-action');
                            if(data.status === true){
                                indicator.classList.remove('text-muted');
                                text.innerText = 'Conectado';
                                indicator.classList.add('text-success');
                                buttons.style.display = 'inline';
                            }else{
                                indicator.classList.add('text-muted');
                                text.innerText = 'No conectado';
                                indicator.classList.remove('text-success');
                                buttons.style.display = 'inline';
                            }
                        }
                        break
                    case 'message':
                        //Para que no se mesclen los mensajes de una conversacion con otra
                        input_message.value = '';

                        if (parseInt(user_id) === parseInt(data.message.user_id) || parseInt(user_id) === parseInt(data.message.reciever_id)) {
                            let html;
                            if (parseInt(user_id) === parseInt(data.message.user_id)) {
                                html = data.mine;
                            } else {
                                html = data.theirs;
                            }

                            messageContainer.innerHTML += html
                            messageContainer.scrollTo(0, messageContainer.scrollHeight);
                        }

                        break;
                    case 'typing':
                        if (parseInt(user_id) === parseInt(data.to)) {
                            if (data.code === "Enter" && data.code === "Alt" && data.code === "Shift" && data.code === "Backspace") {
                                chat_status.style.bottom = '0';
                            } else {
                                chat_status.style.bottom = '60px';
                            }
                            clearTimeout(timeout);
                            timeout = setTimeout(function () {
                                chat_status.style.bottom = '0';
                            }, 2000);
                        }
                        break
                    case 'info':
                        if (parseInt(user_id) === parseInt(data.to)) {
                            messageContainer.innerHTML += data.message
                            messageContainer.scrollTo(0, messageContainer.scrollHeight);
                        }
                        break
                }

            }
        });


    }
    // else {
    //     if (sessionStorage.getItem('is_commercial') === 'false') {
    //          sessionStorage.removeItem('receiver_id');
    //         sessionStorage.removeItem('user_id');
    //         sessionStorage.removeItem('is_commercial');
    //         sessionStorage.removeItem('chat_room');
    //
    //         consumer.subscriptions.subscriptions.forEach(subscription => {
    //             consumer.subscriptions.remove(subscription);
    //         })
    //     }
    // }

})


