import consumer from "./consumer"
import bootbox from "bootbox/bootbox";

let mark_as_read = (id = null) => {
    let items = document.getElementsByClassName('carousel-item');
    let identificador = '';
    for (var i = 0; i < items.length; i++) {
        if (items[i].classList.contains('active')) {
            if (id === null) {
                identificador = items[i].getAttribute('data-notification-id');
            } else {
                identificador = id;
            }

            $.ajax({
                url: `${location.origin}/${sessionStorage.getItem('locale')}/read/notification_push/${identificador}`,
                data: {},
                type: 'GET',
                success: function (data) {
                    // console.log(data);
                }
            })
        }
    }

}

let show_alerts = (array) => {
    let colors = {
        'Urgente': '#e94e4e',
        'Advertencia': '#e9a04e',
        'Notificacion': '#797776'
    }

    let indicator = '';
    let carousel = '';

    array.map((elem, key) => {
        let active = '';
        if (key === 0) {
            active = 'active';
        }
        if (elem.icon.includes('fa')) {
            elem.icon = `fa ${elem.icon}`;
        }

        indicator += `<li data-target="#carouselNotification" data-slide-to="${key}" class="${active} mark_ad_read" data-read-id="${elem.id}"></li>`
        //<span class="${elem.icon} text-justify carousel-icon mr-2" style="--icon-color:${colors[elem.type]};"></span>
        carousel += ` <div class="carousel-item ${active}" data-interval="30000" data-notification-id="${elem.id}">
                               <div class="carousel-body">
                                    <p class="body-title">
                                    <img src="${elem.icon}" alt="">
                                    
                                    ${elem.title}</p>
                                   <p>${elem.description}</p>
                                </div>

                            </div>`
    })

    bootbox.dialog({
        message: `
                        <div id="carouselNotification" class="carousel slide" data-ride="carousel">
                          <ol class="carousel-indicators">
                           ${indicator}
                          </ol>
                          <div id="carousel-inner" class="carousel-inner" style="overflow: visible !important;">
                           ${carousel}
                          </div>
                        </div>
        `
        ,
        className: 'push',
        onShow: function (e) {
            mark_as_read();
            let mar = document.getElementsByClassName('mark_ad_read');
            for (var i = 0; i < mar.length; i++) {
                mar[i].addEventListener('click', function (e) {
                    mark_as_read(e.target.dataset.readId);
                })
            }
        },
        onEscape: false,
        buttons: {
            ok: {
                label: "Leído",
                className: 'btn-notification',
                callback: function () {
                    // console.log('Custom OK clicked');
                }
            }
        }
    });

}

document.addEventListener('turbo:load', () => {
    let notifiy_user = document.getElementById('notificication_user');

    if (notifiy_user !== null) {
        if (!location.pathname.includes('admin') || !location.pathname.includes('maintenance') || !location.pathname.includes('initial')) {
            const user_id = Number(notifiy_user.getAttribute('data-user-id'));
            const notification_push = consumer.subscriptions.create({
                channel: "NotificationPushChannel",
                user_id: user_id
            }, {
                connected() {
                    $.ajax({
                        url: `${location.origin}/${sessionStorage.getItem('locale')}/get/notification_push`,
                        data: {},
                        type: 'GET',
                        success: function (data) {
                            if (data.length > 0) {
                                show_alerts(data);
                            }

                        }
                    })
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                received(data) {

                    switch (data.type) {
                        case 'push':
                            let json = [];
                            json.push(data.notification);
                            show_alerts(json);
                            break;
                        case 'connection':
                            let datos = data.notification;
                            toastr[datos.type](datos.msg, datos.error)
                            break;
                    }

                }
            });
        }
    }
})
