import {Controller} from "stimulus"
import $ from 'jquery';
import toastr from 'toastr/toastr'
import bootbox from 'bootbox/bootbox';
import 'toastr/toastr.scss'

export default class extends Controller {


    connect() {
        $('.modifi_behavior_carousel').carousel({
            interval: 10000
        })
    }
}