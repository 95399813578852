import {Controller} from "stimulus"
import $ from 'jquery';
import bootbox from 'bootbox/bootbox';
import {validate} from './validate_controller'
import {checkSessionVar, setSessionVar, checkSessionVarWithData} from "./sessionStorage_controller";
import {get_popup} from './popup_controller'
import {loaded, confirm_cart} from './swal_controller'
import {blockBody, unblockBody} from './blockUI_controller'
import Swal from 'sweetalert2/dist/sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'
import {set_locale} from './localize_controller'


require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

let active_step = 0;
let max_step = 0;
let id_price = '';
let id_car = '';


function generateId() {
    return Date.now().toFixed();
}

export let set_reservation_time = async () => {
    let date = new Date();
    let save_date = date.setMinutes(date.getMinutes() + 8);
    let reservation = await setSessionVar('reservation_time', save_date, 'add', false);
    return !!reservation.success;
}

export let get_form_data = (obj) => {

    let result = {};
    let target = $('#' + obj);
    let required_field_input = target.find('input');
    let required_field_select = target.find('select');
    let required_field_checkbox = target.find('checkbox');

    required_field_input.each((index, elem) => {
        let field = $(elem)[0];
        result[field.name] = field.value;
    });

    required_field_select.each((index, elem) => {
        let field = $(elem)[0];
        result[field.name] = field.value;
    });

    required_field_checkbox.each((index, elem) => {
        let checkbox = $(elem)[0];
        result[checkbox.name] = checkbox.value;
    });

    return result;
}

function init() {
    let wizard = $('#step_wizard');
    let tabs = wizard.find('#wizard-header').find('.tab');
    let steps = wizard.find('#wizard-body').find('.step');

    max_step = tabs.length - 1

    tabs[active_step].classList.add("active")
    steps[active_step].classList.add("active")
}

export function sessionItems() {
    sessionStorage.setItem('add_product_url', '');
    sessionStorage.setItem('modify_product_url', '');
    sessionStorage.setItem('comment', '');
    sessionStorage.setItem('immediate_payment', 'yes');
    sessionStorage.setItem('one_item', 'no');
    sessionStorage.setItem('customer_notify', 'yes');
    sessionStorage.setItem('locale', 'es');
    sessionStorage.setItem('only_booking', false);
    sessionStorage.setItem('success_url', location.origin + `/${sessionStorage.getItem('locale')}/payment/success`);
    sessionStorage.setItem('return_url', location.origin + `/${sessionStorage.getItem('locale')}/payment/return`);
    sessionStorage.setItem('error_url', location.origin + `/${sessionStorage.getItem('locale')}/payment/error`);
    sessionStorage.setItem('cancel_url', location.origin + `/${sessionStorage.getItem('locale')}/payment/cancel`);
    // sessionStorage.setItem('modality', sessionStorage.getItem('modalities_code_number'));
    // sessionStorage.setItem('tariff', sessionStorage.getItem('tariff_code_number'));
    sessionStorage.setItem('amount', '1');

}

function active_tab_and_step(index, action) {
    let wizard = $('#step_wizard');
    //Almacenamos en variables los tab y los step para modificarlos
    let tabs = wizard.find('#wizard-header').find('.tab');
    let steps = wizard.find('#wizard-body').find('.step');

    //Tab y step que esta actibvo en el momento
    let active_tabs = wizard.find('#wizard-header').find('.active');
    let active_steps = wizard.find('#wizard-body').find('.active');

    //eliminar class active de los tab que estamos dejando atras
    active_tabs[0].classList.remove("active")
    active_steps[0].classList.remove("active")

    if (action === "next") {
        //adicionar al tab anterior la clase visisted
        active_tabs[0].classList.add("visited")
    }

    if (action === "prev") {
        tabs[index].classList.remove("visited")
        tabs[index + 1].classList.remove("visited")
    }


    //Agregar la class active al siguiente tab que vamos a mostrar
    tabs[index].classList.add("active")
    steps[index].classList.add("active")


}

function Prev() {
    if (active_step > 0) {
        active_step--;
        active_tab_and_step(active_step, 'prev');
    }
}

function Next() {
    if (active_step < max_step) {
        active_step++;
        active_tab_and_step(active_step, 'next');
    }

}

function addLi(text, value, isPrice, currency, isCar, backAfterAction, group_id) {
    let id = generateId();

    if (isPrice) {
        id_price = id;
    }
    if (isCar) {
        id_car = id;
    }


    let html = '<div id="' + id + '" data-back="' + backAfterAction + '">';
    if (typeof (parseFloat(value)) === 'number') {
        if (isPrice) {
            html += '<li class="extra">' +
                '<div><span>' + text + ':</span> <span id="" class="text-change discount_price"><span id="' + text.replace(" ", "_").toLowerCase() + '">' + value + '</span> <span  class="currency_amount">' + currency + '</span></span> </div>' +
                // '<div><span class="close"><span class="fa fa-times" data-value="' + id + '" data-action="click->wizard#removeElement"></span> </span></div>' +
                '</li>';
        } else {
            html += '<li class="extra">' +
                '<div><span>' + text + ':</span> <span id="" class="text-change"><span id="' + text.replace(" ", "_").toLowerCase() + '">' + value + '</span></span> </div>' +
                // '<div><span class="close"><span class="fa fa-times" data-value="' + id + '" data-action="click->wizard#removeElement"></span> </span></div>' +
                '</li>';
        }

    } else {
        html += '<li class="extra">' +
            '<div><span>' + text + ':</span> <span id="' + text.replace(" ", "_").toLowerCase() + '" class="text-change">' + value + '</span></div>' +
            // '<div><span class="close" ><span class="fa fa-times" data-value="' + id + '" data-action="click->wizard#removeElement"></span> </span></div>' +
            '</li>';
    }

    html += '</div>';

    if (group_id !== null) {
        $('#' + group_id).prepend('<span class="close" ><span class="fa fa-times" data-value="' + group_id + '" data-price="' + value + '" data-remove="li" data-action="click->wizard#removeElement"></span> </span>');
    }

    $('#list_data').append(html);
}

function setPrice() {
    let price = 0;
    let elementos = $('.discount_price');
    let discount = $('#discount').val();
    let final_price = $('#final_price');
    let discount_cant = 0;

    elementos.each((key, elem) => {
        price += parseFloat($(elem).text());
    });

    if (discount !== undefined) {
        discount_cant = (parseFloat(price) * discount) / 100;
    }

    final_price.text(parseFloat(price) - parseFloat(discount_cant));
}

function removeElem(id) {
    let back = false;
    let element = $('#' + id);
    if (element[0] !== undefined) {
        if (element[0].dataset.back) {
            back = true;
        }
    }
    element.remove();
    setPrice();
    if (back) {
        Prev();
    }
}

function create_shopping_car() {
    $.ajax({
        url: 'create_shopping_car',
        data: {
            dealer: sessionStorage.getItem('dealer'),
            seller: sessionStorage.getItem('seller'),
            add_product_url: sessionStorage.getItem('add_product_url'),
            modify_product_url: sessionStorage.getItem('modify_product_url'),
            comment: sessionStorage.getItem('comment'),
            immediate_payment: sessionStorage.getItem('immediate_payment'),
            one_item: sessionStorage.getItem('one_item'),
            customer_notify: sessionStorage.getItem('customer_notify'),
            locale: sessionStorage.getItem('locale'),
            only_booking: sessionStorage.getItem('only_booking'),
            success_url: sessionStorage.getItem('success_url'),
            return_url: sessionStorage.getItem('return_url'),
            error_url: sessionStorage.getItem('error_url'),
            cancel_url: sessionStorage.getItem('cancel_url')
        },
        type: "GET",
        success: function (data) {
            if (data.success) {
                setSessionVar('shopping_cart', data.data.code, 'add', false);
                setSessionVar('reference', data.data.reference, 'add', false);
                sessionStorage.setItem('payment_url', data.data.payment_url);
                product_shopping_car('nil', data.data.code);
            } else {
                unblockBody();
                bootbox.alert('No se pudo crear el carro de compras!');
            }
        }
    })
}

export const product_shopping_car = (name, shopping_cart, e) => {
    let data = {
        shopping_cart: shopping_cart || sessionStorage.getItem('shopping_cart'),
        dealer: sessionStorage.getItem('dealer'),
        from_date: sessionStorage.getItem('date_collect'),
        to_date: sessionStorage.getItem('date_delivery'),
        amount: sessionStorage.getItem('amount'),
        contract: sessionStorage.getItem('contract_code'),
        modality: sessionStorage.getItem('modality'),
        product: sessionStorage.getItem('category'),
        pick_up_location: sessionStorage.getItem('pick_and_drop'),
        drop_off_location: sessionStorage.getItem('pick_and_drop'),
        pick_up_time: sessionStorage.getItem('time_collect'),
        drop_off_time: sessionStorage.getItem('time_delivery'),
        seller: sessionStorage.getItem('seller'),
        tariff: sessionStorage.getItem('tariff')
    };

    $.ajax({
        url: 'product_shopping_car',
        data: data,
        type: "GET",
        success: function (data) {

            if (data.data.status.toLowerCase() === 'ok') {
                let price = data.data.amount;
                let split = price.split(' ');
                if (data.data.items > 0) {
                    setSessionVar('price', split[0], 'add', false);
                    setSessionVar('text_price', data.data.amount, 'add', false);
                    setSessionVar('item_id', data.data.item_id, 'add', false);
                    //Añadir los li a la vista de slit
                    // addLi('Vehiculo', name, false, '', true, true);
                    addLi(I18n.t('reservation.sidebar.price'), split[0], true, split[1], true, true, 'product_description');

                    let total_amount = $('#total_amount');
                    $('#currency_amount').text(' ' + split[1]);
                    if (sessionStorage.getItem('price')) {
                        total_amount.text(parseFloat(sessionStorage.getItem('price')) + parseFloat(split[0].split(',').join('')));
                    } else {
                        total_amount.text(parseFloat(split[0].split(',').join('')));
                    }
                    unblockBody();
                } else {
                    sessionStorage.removeItem('item_id');
                }

            } else {
                unblockBody();

                get_popup('product_no_disponibility').then(
                    product_no_disponibility => {
                        Swal.fire(
                            {
                                title: product_no_disponibility.title || data.data.errors.code,
                                text: product_no_disponibility.description || 'No hay disponibilidad para el producto solicitado.',
                                icon: product_no_disponibility.icon || 'error',
                                showCancelButton: false,
                                confirmButtonText: I18n.t('global.popup.product_no_disponibility_confirm'),
                                reverseButtons: true,
                                allowOutsideClick: false,
                                backdrop: `rgba(255, 255, 255, 1)`,
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                },
                                buttonsStyling: false
                            }
                        ).then(() => {
                            setSessionVar('', '', 'clear', false).then(
                                result => {
                                    location.href = `/${sessionStorage.getItem('locale')}`;
                                }
                            )

                        });
                    }
                )


            }
        }
    })


    return true;
}

const delete_service_shopping_cart = () => new Promise((resolve, reject) => {
    $.ajax({
        url: 'delete_service_shopping_cart',
        data: {
            shopping_cart: sessionStorage.getItem('shopping_cart'),
            item_id: sessionStorage.getItem('item_id')
        },
        type: 'GET',
        success: function (data) {
            resolve({
                code: data.code,
                success: data.success,
                status: data.data.status,
                amount: data.data.amount
            })
        }
    })
})

export let cancel_shopping_cart = () => new Promise((resolve, reject) => {
    blockBody();
    $.ajax({
        url: 'cancel_shopping_cart',
        data: {
            shopping_cart: sessionStorage.getItem('shopping_cart')
        },
        type: 'GET',
        success: function (data) {
            resolve({
                code: data.code,
                success: data.success,
                status: data.data.status,
                amount: data.data.amount
            })
        }
    })
})

export const get_terms = () => {
    $.ajax({
        url: 'get_terms',
        data: {
            seller: sessionStorage.getItem('seller')
        },
        type: "GET",
        success: function (data) {
            $('#terms_condition').html(data.terms);
        }
    })
}

function payment_type(merchant, unblock) {
    setSessionVar('merchant', merchant, 'add', false);

    let payment_button = $('#payButton');
    payment_button.attr('disabled', '');

    $.ajax({
        url: 'payment_type',
        data: {
            shopping_cart: sessionStorage.getItem('shopping_cart'),
            merchant: merchant,
        },
        type: "GET",
        success: function (data) {
            if (data.success) {
                $('#' + unblock).unblock(
                    {
                        onUnblock: function () {
                            $('#' + unblock).block({
                                message: `<div class="">${I18n.t('reservation.main.steps_body.four.payment_gateway')}</div>`,
                                css: {
                                    border: 'none',
                                    backgroundColor: 'rgba(0,0,0,0)',
                                    color: '#0056A3'
                                },
                                overlayCSS: {
                                    backgroundColor: '#FFFFFF',
                                    opacity: 1,
                                    cursor: 'wait'
                                },
                                timeout: 2000
                            })
                        }
                    }
                );
                if (data.data.status.toLowerCase() === "ok") {
                    let change_price = data.data.amount;
                    let text_price = $('#precio');
                    let text_total_amount = $('#total_amount');
                    let text_currency = $('.currency_amount');
                    setSessionVar('price', change_price.split(' ')[0], 'add', false);
                    setSessionVar('text_price', change_price, 'add', false);
                    text_price.text(change_price.split(' ')[0]);
                    text_total_amount.text(change_price.split(' ')[0]);
                    text_currency.map((key, elem) => {
                        elem.innerText = change_price.split(' ')[1];
                    })


                    payment_button.removeAttr('disabled');
                }else{
                    blockBody();
                    set_locale().then(
                        locale => {
                            location.href = `/${locale.locale}/`
                        }
                    )

                }
            }
        }
    })
}

export function confirm_shopping_car(credit_card_data = null) {
    let data = {
        seller: sessionStorage.getItem('seller'),
        shopping_cart: sessionStorage.getItem('shopping_cart'),
        email: $('#email_user').val(),
        first_name: $('#first_name').val(),
        last_name: $('#last_name').val(),
        reservation_passport: $('#reservation_passport').val(),
        reservation_country: $('#reservation_country').val().toLowerCase(),
        reservation_address: $('#reservation_address').val(),
        reservation_city: $('#reservation_city').val()
    };
    if (credit_card_data !== null) {
        data = Object.assign(data, credit_card_data);
    }

    $.ajax({
        url: 'confirm_shopping_car',
        data: data,
        type: "GET",
        success: function (data) {
            if (data.success) {
                if (data.data.status !== 'FAIL') {
                    create_history().then(
                        result => {
                            if (result.success) {
                                sessionStorage.setItem('payment_status', data.data.payment_status);
                                sessionStorage.setItem('payment_url', data.data.payment_url);

                                confirm_cart(data.data.payment_url);

                            }
                        }
                    );
                } else {
                    loaded(true, data.data.errors.code, data.data.errors.str, 'danger');
                }

            } else {
                let pay_btn = $('#finish_action_btn');
                if (pay_btn !== undefined) {
                    pay_btn.unblock();
                }
                setSessionVar('', '', 'clear', false);
                Swal.fire(
                    {
                        title: data.data.msg,
                        text: data.data.description,
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: I18n.t('global.popup.create_shopping_cart_confirm'),
                        reverseButtons: true,
                        allowOutsideClick: false,
                        backdrop: `rgba(255, 255, 255, 1)`,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false
                    }
                ).then(() => {
                    blockBody();
                    location.href = `/${sessionStorage.getItem('locale')}`;
                });
            }
        }
    })
}

let create_history = () => new Promise((resolve, reject) => {
    $.ajax({
        url: 'create_history',
        data: {
            code: sessionStorage.getItem('shopping_cart'),
            seller: sessionStorage.getItem('seller'),
            product_reference: sessionStorage.getItem('reference'),
            dealer_name: sessionStorage.getItem('dealer_name'),
            payment_url: sessionStorage.getItem('payment_url'),
            vehicle_type: sessionStorage.getItem('text_category'),
            price: sessionStorage.getItem('text_price'),
            merchant: sessionStorage.getItem('merchant')

        },
        type: "GET",
        success: function (data) {
            resolve(data);
        }
    });

})
export let set_status_history = () => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin+`/${sessionStorage.getItem('locale')}/change_history_status`,
        data: {

        },
        type: "GET",
        success: function (data) {
            resolve(data);
        }
    });

})

function booking_info_code() {
    $.ajax({
        url: 'booking_info_code',
        data: {
            api_key: sessionStorage.getItem('seller'),
            shopping_cart: sessionStorage.getItem('shopping_cart'),
        },
        type: "GET",
        success: function (data) {
            console.log(data);
        }
    })
}

function get_payment_info() {
    $.ajax({
        url: `/${sessionStorage.getItem('locale')}/information/active_information.json`,
        data: {},
        type: 'GET',
        success: function (info) {
            let html = '';
            info.map((i) => {
                html += `
            <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#info_${i.id}" aria-expanded="true" aria-controls="info_${i.id}">
                           ${i.title}
                          </button>
                        </h5>
                      </div>

                      <div id="info_${i.id}" class="collapse" aria-labelledby="headingOne" data-parent="#info_acordeon">
                        <div class="card-body">
                          ${i.descriptions.body}
                        </div>
                      </div>
                    </div>
            `;
            });

            $('#info_acordeon').html(html);

        }
    })
}

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
};


export default class extends Controller {

    initialize() {
        set_locale().then(
            locale => {

                if (sessionStorage.getItem('inPayProccess') === 'false') {
                    init();
                    setSessionVar('merchant', 'bdi', 'add', false);

                    if (sessionStorage.hasOwnProperty('shopping_cart')) {
                        let split = sessionStorage.getItem('text_price').split(' ');
                        addLi(I18n.t('reservation.side_bar.price'), split[0], true, split[1], true, true, 'product_description');
                        $('#total_amount').text(split[0]);
                        $('#currency_amount').text(' ' + split[1]);
                        unblockBody();
                    } else {
                        set_reservation_time();
                        sessionItems();
                        create_shopping_car();
                    }

                    //Obtener los terminos y condiciones del sistema
                    get_terms();

                    $('#check_terms').on('change', function () {
                        let target = $('#' + $(this)[0].dataset.target);
                        if ($(this).is(':checked')) {
                            target.removeAttr('disabled');
                        } else {
                            target.attr('disabled', '');
                        }
                    })

                    $('#get_booking').on('click', function () {
                        // booking_info_code();
                        // $('#prestatario').html('Prestatario');
                        // $('#destino').html('Prestatario');
                        // $('#lugar_recogida').html('Prestatario');
                        // $('#fh_recogida').html('Prestatario');
                        // $('#fh_entrega').html('Prestatario');
                        // $('#categoria_auto').html('Prestatario');
                        $('#precio_pagar').html($('#precio').text());
                    })
                } else {
                    if (!sessionStorage.hasOwnProperty('inPayProccess')) {
                        unblockBody();
                        setSessionVar('', '', 'clear', false);
                        get_popup('without_rent').then(
                            no_rent => {
                                Swal.fire(
                                    {
                                        title: no_rent.title || 'Reserva',
                                        text: no_rent.description || "Usted no cuenta con ningun proceso de reserva o pago activo",
                                        icon: no_rent.icon || 'error',
                                        showCancelButton: false,
                                        confirmButtonText: I18n.t('global.popup.without_rent_confirm'),
                                        reverseButtons: true,
                                        allowOutsideClick: false,
                                        backdrop: `rgba(255, 255, 255, 1)`,
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                        },
                                        buttonsStyling: false
                                    }
                                ).then(() => {
                                    blockBody();
                                    location.href = `/${locale.locale}`;
                                });
                            }
                        )

                    } else {
                        unblockBody();
                        get_popup('with_rent_in_pay_process').then(
                            with_rent_in_pay_process => {
                                Swal.fire({
                                    title: with_rent_in_pay_process.title || 'Proceso de pago',
                                    text: with_rent_in_pay_process.description || "Usted cuenta con una reserva en proceso de pago, ¿Desea continuar?",
                                    icon: with_rent_in_pay_process.icon || 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: I18n.t('global.popup.with_rent_in_pay_process_confirm'),
                                    cancelButtonText: I18n.t('global.popup.with_rent_in_pay_process_cancel'),
                                    reverseButtons: true,
                                    allowOutsideClick: false,
                                    backdrop: `rgba(255, 255, 255, 1)`,
                                    customClass: {
                                        confirmButton: 'btn btn-success',
                                        cancelButton: 'btn btn-secondary margin-right-15'
                                    },
                                    buttonsStyling: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        location.href = sessionStorage.getItem('payment_url');
                                    } else {

                                        //with_rent_in_pay_process_cancel
                                        setSessionVar('', '', 'clear', false);
                                        get_popup('with_rent_in_pay_process_cancel').then(
                                            with_rent_in_pay_process_cancel => {
                                                Swal.fire(
                                                    {
                                                        title: with_rent_in_pay_process_cancel.title || 'Proceso de pago',
                                                        text: with_rent_in_pay_process_cancel.description || "Se le ha enviado un e-mail, con los los datos de la reserva y procedimientos para efectuar el pago.",
                                                        icon: with_rent_in_pay_process_cancel.icon || 'info',
                                                        showCancelButton: false,
                                                        confirmButtonText: I18n.t('global.popup.with_rent_in_pay_process_cancel_confirm'),
                                                        reverseButtons: true,
                                                        allowOutsideClick: false,
                                                        backdrop: `rgba(255, 255, 255, 1)`,
                                                        customClass: {
                                                            confirmButton: 'btn btn-success',
                                                        },
                                                        buttonsStyling: false
                                                    }
                                                ).then(() => {
                                                    blockBody();
                                                    location.href = `/${locale.locale}`;
                                                });
                                            }
                                        )


                                    }
                                })
                            }
                        )

                    }

                }
            } )


    }

    connect() {
        let reservation = $('#reservation_country');
        let card_country = $('#card_country');
        let card_type = $('#card_type');
        let country_id = $('#county_id');

        reservation.select2({
            theme: "bootstrap",
            placeholder: "Seleccione una opción",
            ajax: {
                url: `/${sessionStorage.getItem('locale')}/config/countries`,
                dataType: "json",
                data: function (params) {
                    return {
                        search: params.term,
                        page: params.page || 1
                    }

                },
                type: 'get',
                processResults: function (data, params) {
                    let result = []
                    params.page = params.page || 1;

                    data.items.map(elem => {
                        result.push(
                            {
                                id: elem.code,
                                text: elem.name_es
                            }
                        );
                    })

                    if (data.total > 10) {

                        return {
                            results: result,
                            "pagination": {
                                "more": true
                            }
                        }
                    } else {
                        return {
                            results: result,
                            "pagination": {
                                "more": false
                            }
                        }
                    }

                }
            }
        }).on('change', function (e) {

        });

        card_country.select2({
            theme: "bootstrap",
            placeholder: "Seleccione una opción",
            ajax: {
                url: `/${sessionStorage.getItem('locale')}/config/countries`,
                dataType: "json",
                data: function (params) {
                    return {
                        search: params.term,
                        page: params.page || 1
                    }

                },
                type: 'get',
                processResults: function (data, params) {
                    let result = []
                    params.page = params.page || 1;

                    data.items.map(elem => {
                        result.push(
                            {
                                id: elem.code,
                                text: elem.name_es
                            }
                        );
                    })

                    if (data.total > 10) {

                        return {
                            results: result,
                            "pagination": {
                                "more": true
                            }
                        }
                    } else {
                        return {
                            results: result,
                            "pagination": {
                                "more": false
                            }
                        }
                    }

                }
            }
        });
        card_type.select2({
            theme: "bootstrap",
            placeholder: "Seleccione una opción",
        });

        if (country_id !== undefined) {
            $.ajax({
                url: `/${sessionStorage.getItem('locale')}/config/countries/` + country_id.val(),
                data: {"format": "json"},
                type: "GET",
                success: function (data) {
                    var option = new Option(data.name_es, data.code, true, true);
                    reservation.append(option).trigger('change');
                }

            })

        }

        get_payment_info();
    }

    toogleActiveReserva(e) {
        let element = $('.' + e.target.dataset.target)[0];
        let set = $('#'+e.target.dataset.set)[0];

        if (element.classList.contains("show-burguer")) {
            element.classList.remove('show-burguer');
            set.classList.remove('fa-times');
            set.classList.add('fa-car');
        } else {
            element.classList.add('show-burguer');
            set.classList.add('fa-times');
            set.classList.remove('fa-car');
        }

    }


    changePaymentType(e) {

        // if (e.target.dataset.value === 'bdi') {
        //     $('#form_bdi').css({'display': 'block'});
        // } else {
        //     $('#form_bdi').css({'display': 'none'});
        // }

        payment_type(e.target.dataset.value, e.target.dataset.block);

    }


    nextAction(e) {
        if (e.target.dataset.validate !== undefined) {
            validate(e.target.dataset.validate).then(
                next => {
                    if (next.next) {
                        Next();
                    }
                }
        )

        } else {
            Next();
        }


    }

    prevAction() {
        Prev();
    }

    finishAction(e) {
        if (sessionStorage.hasOwnProperty('merchant')) {

            if (e.target.dataset.validate !== undefined && e.target.dataset.merchant === sessionStorage.getItem('merchant')) {
                let next = validate(e.target.dataset.validate);
                if (next.next) {
                    let form_data = get_form_data(e.target.dataset.validate);
                    confirm_shopping_car(form_data);
                }
            } else {
                confirm_shopping_car();
            }

        }

    }


    selectVehicle(e) {
        let card = $('#' + e.target.dataset.value);
        let name = card.find('#vehicle_name').text();
        // let price = card.find('#price').text();
        //
        if (id_price !== '') {
            removeElem(id_price);
        }

        if (id_car !== '') {
            removeElem(id_car);
        }


        product_shopping_car(name);


    }

    removeElement(e) {
        let price = e.target.dataset.price;
        let target = e.target.dataset.value;
        let remove = e.target.dataset.remove;
        let total_amount = $('#total_amount');


        let service_shopping_cart = delete_service_shopping_cart();
        service_shopping_cart.then(result => {
            if (result.status === "OK" && result.success) {
                let valor = parseFloat(total_amount.text()) - parseFloat(price);
                if (valor > 0) {
                    total_amount.text(valor);
                    setSessionVar('price', valor, 'add', false);

                } else {
                    total_amount.text('0');

                    let elementos = $('#' + target).find(remove);
                    elementos.each(function (k) {
                        elementos[k].remove();
                    })

                    setSessionVar('', '', 'clear', false).then(
                        result => {
                            set_locale().then(
                                locale => {
                                    location.href = `/${locale.locale}`;
                                })
                        }
                    )
                }
            }
        })
    }

}