import {Controller} from "stimulus"
import $ from 'jquery';
import toastr from 'toastr/toastr'
import bootbox from 'bootbox/bootbox';
import 'toastr/toastr.scss'
import popper from '@popperjs/core'
import contains from "@popperjs/core/lib/dom-utils/contains";
require('leaflet/dist/leaflet.css')
import 'leaflet'
import flatpickr from "flatpickr";

//Variables
let map = '';
let latlngs = [ [23.966175871265044,-86.011962890625],
    [23.966175871265044,-73.72924804687501  ],
    [19.2489223284628,-73.72924804687501  ],
    [19.2489223284628,-86.011962890625 ]];


let session_type = '';
let session_destination = '';

String.prototype.isEmpty = function () {
    return (this.length === 0 || !this.trim());
}

let obtenerIdCheckDatatable = () => {
    let array = []
    let input = $('#table').find('input[type="checkbox"]');
    input.map(elem => {
        let check = $(input[elem]);
        if (check.is(':checked')) {
            if (check.val() !== 'on') {
                array.push(check.val());
            }
        }

    })
    return array;
}

let contar_caracteres = (max,counter,elem) => {

        //Numero de caracteres permitidos
        var total=max;
        var valor=document.getElementById(elem);
        var respuesta=document.getElementById(counter);
        var cantidad=valor.value.length;

        if(parseInt(cantidad) >= parseInt(total)){
           valor.value = valor.value.slice(0,parseInt(max));
        }

        setTimeout(function(e){
            document.getElementById(counter).innerHTML = cantidad +'/'+ total;
            if(cantidad >= total){
                respuesta.style.background = "#d43f34";
            }else{
                respuesta.style.background = "#6a6866";
            }
        },10);

}


export default class extends Controller {
    initialize(){
        jsColorPicker('.color-picker',{
            mode: 'rgb-b', // initial mode the color picker is starting with
            fps: 60, // the framerate colorPicker refreshes the display if no 'requestAnimationFrame'
            delayOffset: 8, // pixels offset when shifting mouse up/down inside input fields before it starts acting as slider
            CSSPrefix: 'cp-', // the standard prefix for (almost) all class declarations (HTML, CSS)
            size: 3, // one of the 4 sizes: 0 = XXS, 1 = XS, 2 = S, 3 = L (large); resize to see what happens...
            allMixDetails: true, // see Colors...
            noAlpha: true, // disable alpha input (all sliders are gone and current alpha therefore locked)
            cmyOnly: false, // display CMY instead of CMYK
            memoryColors: [{r: 100, g: 200, b: 10, a: 0.8}], // array of colors in memory section
            opacityPositionRelative: undefined, // render opacity slider arrows in px or %
            customCSS: undefined, // if external stylesheet, internal will be ignored...
            appendTo: document.body, // the HTMLElement the colorPicker will be appended to on initialization
            noRangeBackground: false, // performance option: doesn't render backgrounds in input fields if set to false
            textRight: false, // not supported yet. Make numbers appear aligned right
            noHexButton: false, // button next to HEX input could be used for some trigger...
            noResize: false, // enable / disable resizing of colorPicker
            noRGBr: true, // active / passive button right to RGB-R display. Disables rendering of 'real' color possibilities...
            noRGBg: true, // same as above
            noRGBb: true, // same as above
            CSSStrength: 'div.', // not in use
            devPicker: false
        });
        $('.color-picker').map((index,elem) => {
            $(elem).on('keyup',function (e) {
                e.currentTarget.style.background = e.currentTarget.value;
            })
        })

        if(location.pathname.includes('/admin/products')){
            flatpickr('.datepicker', {
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "Y-m-d",
                minDate: new Date(),

            });
            
            $('#product_tipo').select2({
                theme: "bootstrap",
                language: "es"
            }).on('change',function (e) {
                let event_date = $('#events_date');
                if(e.target.value.includes('event')){
                    event_date.removeClass('ocultar');
                }else{
                    event_date.addClass('ocultar');
                }
            })

            
        }


        //PAGES
        if(location.pathname.includes('admin/pages')) {
            let external_url = $('#page_isExternaUrl');
            if(external_url !== undefined && external_url !== null){
                let fields = document.getElementById('fields');


                if(external_url.is(':checked')){
                    // fields.style.display = 'none';
                }
            }

            let page_section_id = $('#page_section_id');
            if(page_section_id.length > 0 || true){
                if(page_section_id.val() !== undefined){
                    $.ajax({
                        url: `${location.origin}/admin/sections/${page_section_id.val()}.json`,
                        data: { },
                        type: 'get',
                        success: function (data) {
                            session_type = data.typesection;
                            session_destination = data.destination;
                            // if((data.typesection === 'presentation_with_image_left' || data.typesection === 'presentation-whit-image-right' || data.destination === 'footer') && !external_url.is(':checked')){

                                document.getElementById('page_icon').style.display = 'inline'
                            // }

                            if(data.typesection === 'presentation-notice-more-events'){
                                document.getElementById('page_with_events').style.display = 'inline';
                            }
                        }
                    })
                }
            }

        }


        //Notification PUSH
        if(location.pathname.includes('admin/notification_pushes')){
            if(location.pathname.includes('new')){
                let check_box_all = document.getElementById("notification_push_notify_all");
                check_box_all.checked = true;
            }

        }

    }
    connect() {

        //    CAMBIOS EN LOS SELECT2 DE LA ADMINISTRACION
        $('#preference_behavior').on('change', function (e) {
            let valor = $(e.target).select2('data')[0].text;
            let elem_unblock = $('#type_' + valor);
            if (elem_unblock !== undefined) {
                elem_unblock.removeClass('ocultar').on('change', function (e) {
                    let other_valor = $(e.target).select2('data')[0].text;
                    let other_elemt_unblock = $('#type_' + other_valor);
                    if (other_elemt_unblock !== undefined) {
                        other_elemt_unblock.removeClass('ocultar');
                    }
                });
            }

        });

         //PREFERENCIAS
        $('#preference_key_id').select2({theme: "bootstrap"}).on('change', function (e) {
            let behavior = ''
            let block_imagen = document.getElementById('background_div');

            if ($(e.target).select2('data')[0].text.includes('social')) {
                behavior = 'social';
            } else {
                behavior = 'general';
            }

            if ($(e.target).select2('data')[0].text === 'Nombre del Sitio' || $(e.target).select2('data')[0].text === 'Bloque de presentación'|| $(e.target).select2('data')[0].text === 'Nombre del sitio en el Footer' || $(e.target).select2('data')[0].text === 'Terminos y condiciones'|| $(e.target).select2('data')[0].text === 'Politicas de Privacidad' ) {
                block_imagen.classList.remove('ocultar');
            }else{
                block_imagen.classList.add('ocultar');
            }


            $('#preference_behavior')[0].value = behavior;
        })



        //SECTION

        $('#section_typesection').select2({theme: "bootstrap"}).on('change', function (e) {
            let behavior = ''
            let thumbnail_div = document.getElementById('thumbnail_div');
            let background_div = document.getElementById('background_div');
            let section_top_div = document.getElementById('divider_top_div');
            let section_div_bottom = document.getElementById('divider_bottom_div');

            if($(e.target).select2('data')[0].id === 'presentation-paralax-with-3-columns'){
                section_div_bottom.classList.remove('ocultar');
            }else{
                section_div_bottom.classList.add('ocultar');
                document.getElementById(section_div_bottom.dataset.clean).value = "";
                document.getElementById(section_div_bottom.dataset.clean).setAttribute('disabled','disabled');
                $('#'+section_div_bottom.dataset.uncheck).prop('checked',false);
            }

            if($(e.target).select2('data')[0].id === 'presentation-information' || $(e.target).select2('data')[0].id === 'presentation-with-map'|| $(e.target).select2('data')[0].id === 'presentation-gallery'){
                section_top_div.classList.add('ocultar');
                section_div_bottom.classList.add('ocultar');
                //LIMPIAR el TOP DIVIDER
                document.getElementById(section_top_div.dataset.clean).value = "";
                document.getElementById(section_top_div.dataset.clean).setAttribute('disabled','disabled');
                $('#'+section_top_div.dataset.uncheck).prop('checked',false);

                //LIMPIAR EL BOTTOM DIVIDER
                document.getElementById(section_div_bottom.dataset.clean).value = "";
                document.getElementById(section_div_bottom.dataset.clean).setAttribute('disabled','disabled');
                $('#'+section_div_bottom.dataset.uncheck).prop('checked',false);
            }else{
                section_top_div.classList.remove('ocultar');
                section_div_bottom.classList.remove('ocultar');
            }

            if ($(e.target).select2('data')[0].text.includes('footer')) {
                behavior = 'footer';

                section_top_div.classList.add('ocultar');
                section_div_bottom.classList.add('ocultar');
                //LIMPIAR el TOP DIVIDER
                document.getElementById(section_top_div.dataset.clean).value = "";
                document.getElementById(section_top_div.dataset.clean).setAttribute('disabled','disabled');
                $('#'+section_top_div.dataset.uncheck).prop('checked',false);

                //LIMPIAR EL BOTTOM DIVIDER
                document.getElementById(section_div_bottom.dataset.clean).value = "";
                document.getElementById(section_div_bottom.dataset.clean).setAttribute('disabled','disabled');
                $('#'+section_div_bottom.dataset.uncheck).prop('checked',false);

                //LIMPIAR Thumbnails
                thumbnail_div.classList.add('ocultar');
                document.getElementById(thumbnail_div.dataset.clean).value = "";
                document.getElementById(thumbnail_div.dataset.clean).setAttribute('disabled','disabled');
                $('#'+thumbnail_div.dataset.uncheck).prop('checked',false);

                //LIMPIAR Background image
                background_div.classList.add('ocultar');
                document.getElementById(background_div.dataset.clean).value = "";
                document.getElementById(background_div.dataset.clean).setAttribute('disabled','disabled');
                $('#'+background_div.dataset.uncheck).prop('checked',false);

            } else {
                behavior = 'body';
                thumbnail_div.classList.remove('ocultar');
                background_div.classList.remove('ocultar');
            }

            $('#section_destination')[0].value = behavior;
        })

        //PAGES
        let page_external = $('#page_isExternaUrl');
        if(page_external !== undefined && page_external !== null){

            let fields = document.getElementById('fields');
            let access_url = document.getElementById('page_access_url');
            let acces_url_value = '';
            let page_icon = document.getElementById('page_icon');
            let tipo = document.getElementById('page_with_events');
            if(access_url !== undefined && access_url !== null){
                 acces_url_value = access_url.value;
            }


            page_external.on('change',function (e) {
                if($(e.target).is(':checked')){
                    access_url.value = 'https://';
                    // fields.style.display = 'none';
                    // page_icon.style.display = 'none';
                    // tipo.style.display = 'none';
                }else{

                    access_url.value = acces_url_value;
                    // fields.style.display = 'block';
                    if((session_type === 'presentation_with_image_left' || session_type === 'presentation-whit-image-right' || session_destination === 'footer')){

                        page_icon.style.display = 'inline';
                    }

                    if(session_type === 'presentation-notice-more-events'){
                        tipo.style.display = 'inline';
                    }
                }
            })
        }

        //NOTIFICATION PUSH
        let user_check = $('#notification_push_users_notification');
        if(user_check !== undefined && user_check !== null){
            let users_select = document.getElementById('users_select');
            $('#notification_push_notify_all').on('change',function (e) {

                if($(e.target).is(':checked')){
                    users_select.style.display = 'none';
                }else{
                    users_select.style.display = 'block';
                }
            })
        }

        //IMFORMATION AND NOTICE
        $('#information_behavior').select2({theme: "bootstrap"}).on('change', function (e){
            let value = $(e.target).select2('data')[0].id;
            let background_image = document.getElementById('background_image');
           if (value === 'notice'){
               background_image.classList.remove('ocultar');
           }else{
               background_image.classList.add('ocultar');
           }
        })
    }
    importData(e) {
        let url = location.origin + '/' + e.target.dataset.url;
        $.ajax({
            url: url,
            data: {},
            type: 'GET',
            success: function (data) {
                if (data.success) {
                    toastr.success(`(${data.count}) ${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`(${data.count}) ${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                }

            }
        })
    }
    setActive(e) {
        let url = location.origin + '/' + e.target.dataset.url;
        $.ajax({
            url: url,
            data: {active: e.target.dataset.active},
            type: 'POST',
            success: function (data) {
                if (data.success) {
                    toastr.success(`${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`${data.msg}`);
                }

            }
        })
    }
    setBlockActive(e) {
        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + e.target.dataset.url;
            $.ajax({
                url: url,
                data: {id: ids},
                type: 'POST',
                success: function (data) {
                    if (data.success) {
                        toastr.success(`${data.msg}`);
                        $('#table').bootstrapTable('refresh');
                    } else {
                        toastr.error(`${data.msg}`);
                    }

                }
            })
        }

    }
    counter_characters(e){
        contar_caracteres(e.target.dataset.max,e.target.dataset.counter, e.target.id);
    }
    showImg(e){
        let src = document.getElementById(e.target.dataset.value).value
        bootbox.alert({
            message: `<img src='${src}' style='width: 100%'>`,
            buttons: {
                ok: {
                    label: "<span class='fa fa-check'></span> Visto",
                    className: 'btn-info'
                }
            }
        })
    }
    unblockElement(e){
        let element = document.getElementById(e.target.id);
        let target = document.getElementById(e.target.dataset.target);
        let property = e.target.dataset.property;

        target.toggleAttribute(property)

        if(!$('#'+e.target.id).is(':checked')){
            target.value = "";
            if(target.dataset.hasOwnProperty('target')){
                let sub_target = document.getElementById(target.dataset.target);
                sub_target.classList.add('ocultar');
            }
        }
    }
    setAttributedata(e){
        setTimeout(function () {
            let element = document.getElementById(e.target.id);
            let target = document.getElementById(e.target.dataset.target);
            target.setAttribute(e.target.dataset.property,element.value);

            if(element.value.isEmpty()){
                target.classList.add('ocultar');
            }else{
                target.classList.remove('ocultar');
            }
        },100)


    }
    typeUrl(e){
       let value = e.target.value.split(" ");
       e.target.value = value.join("-");
    }
    setColor(e){
        let element = e.target;
        let value = element.value;
        let opacity30 = document.getElementById(e.target.dataset.opacity30);
        let opacity50 = document.getElementById(e.target.dataset.opacity50);
        let opacity80 = document.getElementById(e.target.dataset.opacity80);


        if(value.includes('#')){
            if(opacity30 !== undefined && opacity30 !== null){
                opacity30.value = `${value}5c`;
                opacity30.style.background = `${value}5c`;
            }
            if(opacity50 !== undefined && opacity50 !== null){
                opacity50.value = `${value}BB`;
                opacity50.style.background = `${value}BB`;
            }
            if(opacity80 !== undefined && opacity80 !== null){
                opacity80.value = `${value}DD`;
                opacity80.style.background = `${value}DD`;
            }
        }
    }
    copyForBackground(e){
        var copyTextarea = document.createElement("textarea");
        copyTextarea.style.position = "fixed";
        copyTextarea.style.opacity = "0";
        copyTextarea.textContent = `${document.getElementById(e.target.dataset.id).value}`;

        document.body.appendChild(copyTextarea);
        copyTextarea.select();
        if (document.execCommand("copy")){
            toastr.success('Url copiada al portapapeles!!!','Copiar url');
        }else{
            toastr.error('Upps ha ocurrido un error, no se pudo copiar la url.','Copiar url');
        }
        document.body.removeChild(copyTextarea);
    }

    deleteBlock(e) {
        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + e.target.dataset.url;
            $.ajax({
                url: url,
                data: {id: ids},
                type: 'POST',
                success: function (data) {
                    if (data.success) {
                        toastr.success(`${data.msg}`);
                        $('#table').bootstrapTable('refresh');
                    } else {
                        toastr.error(`${data.msg}`);
                    }

                }
            })
        }

    }
}