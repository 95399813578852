import { Controller } from "stimulus"
import $ from 'jquery';

function formValid(valid){
    let password = $('#user_password');
    let password_confirmation = $('#user_password_confirmation');
    let submit = $('input:submit');
    if(valid){
        password.removeClass('has-error')
        password.addClass('has-success')
        password_confirmation.removeClass('has-error')
        password_confirmation.addClass('has-success')
        submit.attr("disabled", false)
    }else{
        password.addClass('has-error')
        password.removeClass('has-success')
        password_confirmation.addClass('has-error')
        password_confirmation.removeClass('has-success')
        submit.attr("disabled", true)
    }
}

export default class extends Controller {

    connect(){
       $('#user_password').keyup(this.validatePassword);
       $('#user_password_confirmation').keyup(this.validatePassword);
    }

    validatePassword(){
        let password = $('#user_password').val();
        let password_confirmation = $('#user_password_confirmation').val();

        if(password.length < 8){
            formValid(false);
            $('input:submit').attr("value","Mínimo 8 caracteres.");
        }else{
            if(password === password_confirmation){
               formValid(true)
                $('input:submit').attr("value","Cambiar contraseña.");
            }else{
                formValid(false)
                $('input:submit').attr("value","Las contraseñas no coinciden.");
            }
        }
    }


}