import {Controller} from "stimulus"
import $ from 'jquery';
import {set_locale} from './localize_controller'

require("block-ui/jquery.blockUI")

export let blockBody = () => {
    let img = $('#loader_img')[0];

    $.blockUI({
        theme: false,
        css: {
            padding:        0,
            margin:         0,
            width:          '100%',
            top:            '0',
            left:           '0',
            textAlign:      'center',
            color:          '#000',
            border:         'none',
            backgroundColor:'#fff',
            cursor:         'none'
        },
        overlayCSS: {
            backgroundColor: '#ffffff',
            opacity: '1'
        },
        fadeIn:  800,
        message:  `<div class="loader_container">
                        <div class='loader'>
                             <div id="loader_front" class="loader-img" style="--i:url('${img.dataset.img}');"></div>
                             <div id="loader_back" class="loader-img" style="--i:url('${img.dataset.img}');"></div>
                             <div id="loader_left" class="loader-img" style="--i:url('${img.dataset.img}');"></div>
                             <div id="loader_right" class="loader-img" style="--i:url('${img.dataset.img}');"></div>
                             <div id="loader_top" class="loader-img" style="--i:url('${img.dataset.img}');"></div>
                             <div id="loader_bottom" class="loader-img" style="--i:url('${img.dataset.img}');"></div>
                        </div>
                        <div class="loader-text">${I18n.t('global.loader')}</div>
                    </div>`
    })
}

export let unblockBody = () => {
     $.unblockUI({ fadeOut: 1500 });

}

export let blockElementsById = (id, msg,background_text ='rgba(0,0,0,0)',color_text = '#0056A3',background_overlay = '#FFFFFF') => {
    let block_element = $('#'+id);

    block_element.block({
        message: `${msg}`,
        css:{
            border: 'none',
            backgroundColor: `${background_text}`,
            color: `${color_text}`,
            backdropFilter: 'blur(2px)'
        },
        overlayCSS:  {
            backgroundColor: `${background_overlay}`,
            opacity:         1,
            cursor:          'wait',
            backdropFilter: 'blur(2px)',
            borderRadius: '5px'
        },
    });
}

export let unBlockElementById = (id) => {
    let block_element = $('#'+id);
    block_element.unblock();
}

export default class extends Controller {

    connect() {
        set_locale().then(
            locale => {
                blockBody();
            }
        )

    }

    blockElement(e){
        blockElementsById(e.target.dataset.block,e.target.dataset.blockMessage);

    }
}