import {Controller} from "stimulus"
import $ from 'jquery';
import {unblockBody} from './blockUI_controller'



export default class extends Controller {

    connect() {
       let div = $('.backgroud-paralax');
       let childrens = div.children();
       $.ajax({
           url: `${location.origin}/${sessionStorage.getItem('locale')}/admin/${div[0].dataset.type}/json?id=${div[0].dataset.img}`,
           data: {},
           type: 'GET',
           success: function (data) {
               data = data[0];
               if(data.background_image !== undefined){
                   div.css({
                       'background-image': 'url("'+data.background_image+'")'
                   })
               }else{
                   childrens.map( (key, child) => {
                       child.classList.add('change-background')
                   })
               }

               unblockBody();

           }
       })

    }
}