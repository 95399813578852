import {Controller} from "stimulus"
import $ from 'jquery';




export default class extends Controller {

    connect() {
       let last_word = $('.last-word-change');
       let last_word_uppercase = $('.last-word-uppercase-change');
       let last_word_zoom = $('.last-word-zoom');

       last_word.map((index, elem) => {
           if(last_word.hasOwnProperty(index)){
               if(!last_word[index].innerHTML.includes('last-word')){
                   let text = last_word[index].innerText;
                   text = text.trim();
                   text = text.split(' ');
                   let last = text.pop();
                   text = text.join(' ');
                   text = text+' '+'<span class="last-word">'+last+'</span>';
                   last_word[index].innerHTML = text;
               }

           }
       })

        last_word_uppercase.map((index, elem) => {
            if(last_word_uppercase.hasOwnProperty(index)){
                if(!last_word[index].innerHTML.includes('last-word-upercase')) {
                    let text = last_word_uppercase[index].innerText;
                    text = text.trim();
                    text = text.split(' ');
                    let last = text.pop();
                    text = text.join(' ');
                    text = text + ' ' + '<h1><p class="last-word-upercase">' + last + '</p></h1>';

                    last_word_uppercase[index].innerHTML = text;
                }
            }
        })
        last_word_zoom.map((index, elem) => {
            if(last_word_zoom.hasOwnProperty(index)){
                let text = $('#'+last_word_zoom[index].dataset.source).text();
                text = text.trim();
                text = text.split(' ');
                let last = text.pop();
                text = text.join(' ');
                let target = last_word_zoom[index].dataset.target;
                $('#'+target)[0].innerHTML = last.toUpperCase();
                last_word_zoom[index].innerHTML = text;
            }
        })
    }
}