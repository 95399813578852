import {Controller} from "stimulus"
import $ from 'jquery';

export default class extends Controller {

    connect() {

    }

    setClass(e) {
        e.target.classList.toggle(e.target.dataset.class);
    }

    showPass(e) {

        e.target.classList.toggle('show');
        switch (document.getElementById(e.target.dataset.target).type) {
            case 'password':
                document.getElementById(e.target.dataset.target).type = 'text';
                break;
            default:
                document.getElementById(e.target.dataset.target).type = 'password';
                break;

        }
    }
}