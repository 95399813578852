import {Controller} from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

export let catalog = () => new Promise((resolve, reject) =>{
    $.ajax({
        url: `/${sessionStorage.getItem('locale')}/admin/catalogs/data`,
        data: {
            catalog: true
        },
        type: 'GET',
        success: function (catalog) {
            if(catalog.total > 0){
                resolve({
                    success: true,
                    data: catalog.rows,
                    code: 200
                });
            }else{
                resolve({
                    success: false,
                    data: {},
                    code: 500
                })
            }
        }
    })
})
export let preferences = (text) =>new Promise((resolve, reject)=>{
    $.ajax({
        url: location.origin + `/${sessionStorage.getItem('locale')}/config/get_preference`,
        data: {
            text: text
        },
        type: 'GET',
        success: function (data) {
            resolve(data[0]);
        }
    })
})

let get_category_product = (category) => new Promise((resolve, reject) => {
    $.ajax({
        url: location.origin+`/${sessionStorage.getItem('locale')}/admin/products/get_categories_active`,
        data: {

        },
        type: "GET",
        success: function (data) {
          data.map(elem => {
             if(elem.code === category){
                resolve(elem)
             }
          })
        }

    })
})
export default class extends Controller {

    initialize(){
        let category_field = $('#change_season_product');
        if(location.pathname.includes('edit')){
            get_category_product(category_field.val()).then(
                category => {
                    let option = new Option(category.name, category.code, true, true);
                    $('#product_category').append(option);
                }
            );
        }
    }

    connect() {
        let category_field = $('#change_season_product_name');
        let category_id_field = $('#change_season_product');

        $('#product_category').select2({
            theme: "bootstrap",
            ajax: {
                url: location.origin+`/${sessionStorage.getItem('locale')}/admin/products/get_categories_active`,
                data: {
                },
                type: "GET",
                processResults: function (data, params) {
                    let result = [];

                   data.map( elem => {
                       result.push({
                           id: elem.code+'|'+elem.name,
                           text: elem.name
                       })
                   })

                        return {
                            results: result
                        }


                }
            }
        }).on('change', function (e) {
            let value = e.target.value;
            value = value.split('|');
            category_field.val(value[value.length-1]);
            $('#select2-product_category-container').text(value[value.length-1]);
            value.pop();
            category_id_field.val(value.join('|'));
        })
    }


}