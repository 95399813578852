import consumer from "./consumer"

function set_read(id) {
  let result;
  $.ajax({
    url: location.origin+`/notifications/set_read`,
    data: {
      id: id
    },
    type: 'POST',
    success: function (data) {
      result = data;
    }
  });

  return result;
}

document.addEventListener('turbo:load', () => {
  const user_element = document.getElementById('user_notification_id')
  if(user_element){
    const user_id = Number(user_element.getAttribute('data-user-id'))
    const notificationContainer = document.getElementById('notification_container')
    consumer.subscriptions.create({channel: "NotificationsChannel", user_id: user_id}, {
      connected() {



        if( Notification.permission === 'default' ) {
          Notification.requestPermission(function (permission) {
            // callback
            if (permission === 'granted') {
              toastr.success('Ahora podra recibir notificaciones de escritorio.','Notificaciones activadas');
            }
          });
        }
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
        if (location.pathname.includes('commercial/rooms/') && location.pathname.includes('commercial/show')){
          let set_valur_read = set_read(data.id);
        }else{

          // Cuando el usuario pierde el foco o sale de tu pestaña (sitio web)
                      let notification_id = Math.floor(Math.random() * (99999999999999999999 - 1)) + 1;
            var opciones = {
              icon: "<?xml version=\"1.0\" standalone=\"no\"?>\n" +
                  "<!DOCTYPE svg PUBLIC \"-//W3C//DTD SVG 20010904//EN\"\n" +
                  " \"http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd\">\n" +
                  "<svg version=\"1.0\" xmlns=\"http://www.w3.org/2000/svg\"\n" +
                  " width=\"200.000000pt\" height=\"200.000000pt\" viewBox=\"0 0 200.000000 200.000000\"\n" +
                  " preserveAspectRatio=\"xMidYMid meet\">\n" +
                  "\n" +
                  "<g transform=\"translate(0.000000,200.000000) scale(0.100000,-0.100000)\"\n" +
                  "fill=\"#000000\" stroke=\"none\">\n" +
                  "<path d=\"M890 1826 c0 -25 13 -100 29 -168 16 -68 32 -140 35 -160 4 -21 11\n" +
                  "-40 16 -43 17 -11 30 45 46 189 8 78 18 160 23 184 l8 42 -78 0 -79 0 0 -44z\"/>\n" +
                  "<path d=\"M290 1657 c0 -87 8 -100 81 -137 40 -21 281 -130 286 -130 2 0 3 12\n" +
                  "3 27 0 57 -58 112 -228 215 -53 33 -107 69 -119 80 l-23 21 0 -76z\"/>\n" +
                  "<path d=\"M1630 1710 c-14 -11 -77 -53 -140 -92 -124 -77 -193 -139 -204 -183\n" +
                  "-11 -42 4 -47 59 -20 28 13 94 42 148 66 54 23 114 56 133 74 33 31 34 35 34\n" +
                  "104 0 39 -1 71 -2 71 -2 0 -14 -9 -28 -20z\"/>\n" +
                  "<path d=\"M775 1332 c-48 -23 -106 -84 -138 -149 -137 -271 67 -571 374 -550\n" +
                  "165 11 279 103 329 265 30 97 27 154 -16 242 -65 137 -95 162 -210 175 -91 10\n" +
                  "-133 -5 -208 -76 -79 -73 -101 -123 -93 -204 11 -105 64 -155 165 -155 60 0\n" +
                  "123 27 147 64 11 17 15 42 13 83 -3 51 -5 58 -24 58 -13 0 -41 -24 -77 -67\n" +
                  "-64 -74 -81 -78 -119 -25 -29 41 -30 116 -2 155 60 81 201 82 285 2 69 -65 76\n" +
                  "-171 19 -287 -58 -116 -155 -169 -269 -144 -141 30 -224 125 -238 274 -10 114\n" +
                  "47 251 129 304 21 15 37 31 35 37 -6 18 -60 17 -102 -2z\"/>\n" +
                  "<path d=\"M585 570 c-33 -16 -100 -48 -150 -70 -49 -23 -102 -53 -117 -67 -26\n" +
                  "-24 -28 -33 -28 -101 l0 -74 38 29 c20 16 73 51 117 78 161 97 215 150 215\n" +
                  "209 0 32 -1 32 -75 -4z\"/>\n" +
                  "<path d=\"M1284 586 c-9 -23 25 -84 68 -121 22 -19 89 -63 147 -99 59 -36 116\n" +
                  "-74 126 -86 10 -11 22 -20 27 -20 15 0 9 134 -7 158 -22 30 -80 62 -210 117\n" +
                  "-60 25 -114 50 -119 55 -14 14 -26 12 -32 -4z\"/>\n" +
                  "<path d=\"M951 463 c-6 -37 -15 -117 -21 -178 -6 -60 -13 -122 -16 -138 l-6\n" +
                  "-27 76 0 c93 0 94 3 66 137 -12 54 -30 136 -39 183 -24 112 -44 120 -60 23z\"/>\n" +
                  "</g>\n" +
                  "</svg>\n", /* (opcional) aún no implementado */
              body: data.text, /* (opcional) si se omite el título será el cuerpo */
              tag: notification_id /* (opcional) Nunca habrá dos notificaciones con la misma etiqueta, así que cuando se muestre se cerrarán las otras que tengan la misma etiqueta */
            }
// Creamos la notificación
            var notification = new window.Notification(data.from, opciones);

            //ACCIONES SOBRE LA NOTIFICACION
            notification.onclick = function() {
              notification.close();
              window.parent.focus();
              window.focus();
              location.href = location.origin+data.url;
            }

            //AGREGAR NOTIFICACION A LA BARRA DE INICIO Y REPRODUCIR SONIDO DE MENSAJE
            if (notificationContainer !== null && notificationContainer !== undefined){
              notificationContainer.innerHTML = data.notification+ notificationContainer.innerHTML;
              let notification_count = document.getElementById('notification_count');
              notification_count.innerHTML = parseInt(notification_count.innerText) + 1;
            }

            const tone_message = new Audio('/assets/tone-msg.mp3');
            tone_message.play();


        }

      }
    })
  }

})
;
