import {Controller} from "stimulus"
import $ from 'jquery';
import {set_locale} from "./localize_controller";

export let setSessionVar = (name, value, action, is_date, save = true, increment= 0) => new Promise ((resolve, reject) => {
    let analytics_var = false;
    if (name === 'enable_analytics') {
        analytics_var = sessionStorage.getItem ('enable_analytics');
    }

    $.ajax ({
        url: location.origin + '/config/set_session_var',
        data: {
            name: name,
            value: value,
            accion: action,
            is_date: is_date,
            increment: increment

        },
        type: 'GET',
        success: function (data) {
            if (data.success) {

                switch (action) {
                    case 'add':
                        if (save) {
                            if (typeof data.value === 'object') {
                                for (const prop in data.value) {
                                    sessionStorage.setItem (prop, data.value[prop]);
                                }
                            } else {
                                sessionStorage.setItem (name, data.value);
                            }
                        }
                        break;
                    case 'delete':
                        sessionStorage.removeItem (name);
                        break;
                    case 'clear':
                        clear_session (analytics_var).then (
                            clear_session_resolve => {
                                console.log (clear_session_resolve);
                            }
                        )
                        break;
                }

                resolve (data);
            }
        },
        error: function (error) {
            resolve ({success: false});
        }
    });
})


export let checkSessionVar = (variable) => new Promise ((resolve, reject) => {
    $.ajax ({
        url: location.origin + `/config/check_session_var`,
        data: {
            variable: variable
        },
        type: 'GET',
        success: async function (data) {
            resolve ({success: data.success});
        }
    });

    return false;
})

export let checkSessionVarWithData = (variable) => new Promise ((resolve, reject) => {

    $.ajax ({
        url: location.origin + `/config/check_session_var`,
        data: {
            variable: variable
        },
        type: 'GET',
        success: async function (data) {
            if (data.success) {
                resolve (data);
            } else {
                await sessionStorage.removeItem (data.data);
                resolve (data);
            }
        }
    });

    return false;
})

export let clear_session = (analytic = false) => new Promise ((resolve, reject) => {

    sessionStorage.clear ();

    setSessionVar ('enable_analytics', analytic, 'add', false).then (
        analytics_session => {
            set_locale ().then (
                locale => {
                    resolve ({
                        success: true,
                        analytic: analytics_session,
                        locale: locale
                    })
                }
            )
        }
    );


})


export default class extends Controller {


}