import { Controller } from "stimulus"
import $ from 'jquery';
import {set_locale} from './localize_controller'

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

export default class extends Controller {

    connect() {
        set_locale().then(
            locale => {
                $('#user_country_id').select2({
                    theme: "bootstrap",
                    language: "es"
                }).on('change',function (e) {
                    $.ajax({
                        url: `/${locale.locale}/config/countries/`+e.target.value,
                        data: { },
                        type: "GET",
                        success: function (data) {
                            $("#user_country_code").val(data.code);
                            $("#user_phone").val(data.dial_code);
                        }
                    })

                });

                let country_code_user = $('#user_country_code');
                if(country_code_user !== undefined){
                    country_code_user.select2({
                        theme: "bootstrap",
                        language: locale.locale,
                        placeholder:'* País',
                        search: false,
                        ajax: {
                            url: `${location.origin}/${locale.locale}/config/countries`,
                            dataType: "json",
                            language: "es",
                            data: function (params) {
                                return {
                                    search: params.term,
                                    page: params.page || 1
                                }

                            },
                            type: 'GET',
                            processResults: function (data, params) {
                                let result = []
                                params.page = params.page || 1;

                                data.items.map(elem => {
                                    result.push(
                                        {
                                            id: elem.code,
                                            text: elem[`name_${locale.locale}`]
                                        }
                                    );
                                })

                                if (data.total > 10) {

                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": true
                                        }
                                    }
                                } else {
                                    return {
                                        results: result,
                                        "pagination": {
                                            "more": false
                                        }
                                    }
                                }

                            }
                        }
                    }).on('change',function (e) {
                    });
                }

            }
        )

    }
}