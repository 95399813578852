import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import reservationContract from "select2"
// require('block-ui/jquery.blockUI')


function formatState (state) {
    if (!state.id) {
        return state.text;
    }

    let number = Math.random()*255|0
    let number1 = Math.random()*255|0
    let number2 = Math.random()*255|0

    let file = '';
    if(state.type.includes('image')){
        file = `<span style="display: block;width: 100px; height: 100px; background-image: url(${state.file});background-position: center;
                background-size: cover;
                background-repeat: no-repeat; border-radius: 50px; position: relative; overflow: hidden"></span>`
    }else{
            file = `<span style="display: flex;width: 100px; height: 100px; background-color: rgba(${number},${number1},${number2}); border-radius: 50px;
                    position: relative;
                    overflow: hidden;
                    justify-content: center;
                    align-items: center;
                    font-size: 4em;
                    text-transform: uppercase;
                    text-shadow: 3px 3px .25rem rgba(0,0,0,.5);
                    font-weight: bold;">${state.text.charAt(0)}</span>`;
    }


    var template = $(
        `<span style="display: flex; justify-content: flex-start; align-items: center; gap: 25px;">
            ${file}  
            <span style="display: flex;flex-flow: column wrap; justify-content: center; ">
                <p style="grid-area: text;text-transform: uppercase;
                        font-size: 1.3em;
                        font-weight: bold;
                        color: #343434;">${state.text}</p>
                <span style=" display: flex; justify-content: flex-start; gap: 0 25px;">
                    <p style="font-size: .9em;
                                font-weight: 350;
                                color: #959393;">Tamaño: ${state.size}</p>
                    <p style="font-size: .9em;
                                font-weight: 350;
                                color: #959393;">Dimensiones: ${state.dimention}</p>
                    <p style="font-size: .9em;
                                font-weight: 350;
                                color: #959393;">Mime/Type: ${state.type}</p>
                </span>
                
            </span>
        </span>
        `
    );
    return template;
}

export default class extends Controller {

    connect() {
        $('.c2ec_select').select2({
            theme: "bootstrap",
            language: "es"
            // placeholder: "Seleccione una opción"
        });
        $('.c2ec_select_with_clear').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción",
            allowClear: true
        });

        let media_select = $('.media_select');
        if(media_select !== undefined && media_select.length > 0){
            media_select.map((index,elem) => {

                let padre_dataset = elem.dataset;

                $.ajax({
                    url: `${location.origin}/admin/media_galeries/json`,
                    data:{
                        mime: padre_dataset.mime,
                        witout_div: true
                    },
                    type: 'GET',
                    success: function (data) {
                        let rows = [];
                        data.map((el, index) => {
                            let selected = false;
                            if(padre_dataset.value !== '' && padre_dataset.value === el.reference){
                                selected = true
                            }
                            rows.push({
                                id: el.reference,
                                text: el.name,
                                size: el.size,
                                type: el.mime,
                                file: el.file,
                                dimention: el.dimention,
                                selected: selected
                            })
                        });
                        $(elem).select2({
                            theme: "bootstrap",
                            language: "es",
                            placeholder: "Seleccione una opción",
                            data: rows,
                            templateResult: formatState,
                        }).on('change',function (change) {
                            // alert(change.target.value);
                        })

                    }
                })


            })
        }
    }
}