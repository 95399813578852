import {Controller} from "stimulus"
import $ from 'jquery';
import {setSessionVar} from "./sessionStorage_controller";
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";
import {english} from "flatpickr/dist/l10n/default";
import {get_contract} from './rentaContractModalityTariff_controller'

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

let url_dealer, url_collect_place, url_mark, url_model,
    verbose_dealer, verbose_collect,
    verbose_mark, verbose_model, recogida,flatpick_lang,
    date_disabled = [];

let disable_dates = () => new Promise((resolve, reject) =>{
    $.ajax({
        url: location.origin+"/admin/disable_dates/disabled",
        data: {

        },
        type: "GET",
        success: function (data) {
            resolve(data);
        }
    })
})

let verify_season = (date) => new Promise((resolve, reject) => {

    $.ajax({
        url: location.origin+"/verify_season",
        data: {
            date: date,
            category: sessionStorage.getItem("category")
        },
        type: "GET",
        success: function (data) {
            if(data.success){
                resolve(data.data);
            }else{
                console.log(data);
            }
        }

    })
})

export default class extends Controller {

    initialize() {
        let collect_place_pr = $('#collect_place_pr');
        let destination_pr = $('#destination_pre_reserva');
        let date_collect_pr = $('#date_collect_pr');
        let time_collect_pr = $('#time_collect_pr');

        disable_dates().then(
            dd => {
                date_disabled = dd;
            }
        )

        switch (I18n.locale) {
            case 'es':
                flatpick_lang = Spanish;
                break;
            case 'en':
                flatpick_lang = english;
                break;
        }

        collect_place_pr.select2({
            theme: "bootstrap"
        }).prop("disabled", true);
        destination_pr.select2({
            theme: "bootstrap"
        });

        $.ajax(
            {
                url: `/${sessionStorage.getItem('locale')}/preferences/to/general.json`,
                data: {},
                type: "get",
                success: function (data) {

                    data.map((elem, key) => {
                        if (data.hasOwnProperty(key)) {
                            switch (elem.key.text) {
                                case "category_url":
                                    url_dealer = elem.id
                                    verbose_dealer = elem.verbose
                                    break;
                                case "url_collect_place":
                                    url_collect_place = elem.id
                                    verbose_collect = elem.verbose
                                    break;
                                case "url_mark":
                                    url_mark = elem.id
                                    verbose_mark = elem.verbose
                                    break;
                                case "url_model":
                                    url_model = elem.id
                                    verbose_model = elem.verbose
                                    break;
                            }

                        }
                    })
                },
                complete: function (data) {
                    destination_pr.select2({
                        theme: "bootstrap",
                        placeholder: I18n.t('global.main.form.select_destiny'),
                        ajax: {
                            url: 'destination_by_service',
                            data: {
                                url: url_dealer,
                                category: sessionStorage.getItem('category'),
                                seller: sessionStorage.getItem('seller')
                            },
                            type: "GET",
                            processResults: function (data, params) {
                                let result = [];
                                data.map((elem, key) => {

                                    result.push({
                                        text: elem.name,
                                        id: elem.code
                                    });
                                })

                                return {
                                    results: result
                                }

                            }
                        }
                    }).on('change', function (e) {
                        setSessionVar('destination', e.target.value, 'add', false).then(
                            destination_result => {
                                setSessionVar('text_destination', $(e.target).select2('data')[0].text, 'add', false);
                                collect_place_pr.select2({
                                    theme: "bootstrap",
                                    placeholder: I18n.t('global.main.form.select_office'),
                                    ajax: {
                                        url: 'office_by_destination',
                                        data: {
                                            url: url_collect_place,
                                            destination: e.target.value
                                        },
                                        type: "GET",
                                        processResults: function (data, params) {
                                            let result = [];
                                            data.offices.map((elem, key) => {
                                                let aux = {
                                                    text: elem.dealer_name,
                                                    children: []
                                                };
                                                if (elem.offices.length > 0) {
                                                    elem.offices.map((child, number) => {
                                                        if (child.active) {
                                                            aux.children.push({
                                                                id: child.code + '-dealer:' + elem.dealer_code + '-dealer_name:' + elem.dealer_name + '-full_time:' + child.full_time + '-open_time:' + child.open_time + '-close_time:' + child.close_time,
                                                                text: child.name
                                                            });
                                                        }

                                                    })

                                                    result.push(aux);
                                                }
                                            })

                                            return {
                                                results: result
                                            }
                                        }
                                    }
                                })
                                    .prop("disabled", false)
                                    .on('change', function (e) {
                                        recogida = e.target.value;
                                        recogida = recogida.split('-');
                                        setSessionVar('pick_and_drop', recogida[0], 'add', false);
                                        setSessionVar('dealer', recogida[1].split('dealer:')[1], 'add', false);
                                        setSessionVar('dealer_name', recogida[2].split('dealer_name:')[1], 'add', false);
                                        setSessionVar('collect_place_text', $(e.target).select2('data')[0].text, 'add', false);
                                        let helper = $(collect_place_pr[0].parentNode).find('.helper-text');
                                        let tootltip_button = $(collect_place_pr[0].parentNode).find('.tooltip-button');
                                        let text_helper = '';
                                        get_contract(recogida[1].split('dealer:')[1]).then(
                                            contract => {
                                                date_collect_pr.prop('disabled', false);
                                                time_collect_pr.prop('disabled', false);


                                                let full_time = recogida[3].split('full_time:')[1];
                                                if (full_time === "false") {
                                                    flatpickr(".timepicker", {
                                                        enableTime: true,
                                                        noCalendar: true,
                                                        dateFormat: "H:i",
                                                        time_24hr: true,
                                                        locale: flatpick_lang,
                                                        disableMobile: "true",
                                                        minTime: recogida[4].split('open_time:')[1] + ":00",
                                                        maxTime: recogida[5].split('close_time:')[1] + ":00",
                                                    })

                                                    let from = recogida[4].split('open_time:')[1] + ":00am";
                                                    let to = (parseInt(recogida[5].split("close_time:")[1]) - 12) + ":00pm";
                                                    text_helper = I18n.t('global.main.form.work_by_hours', {from: from,to: to});
                                                        // text_helper = "Esta oficina trabaja en el horario de 0" + recogida[4].split('open_time:')[1] + ":00am a " + (parseInt(recogida[5].split("close_time:")[1]) - 12) + ":00pm ";
                                                } else {
                                                    flatpickr(".timepicker", {
                                                        enableTime: true,
                                                        noCalendar: true,
                                                        dateFormat: "H:i",
                                                        time_24hr: true,
                                                        locale: flatpick_lang,
                                                        disableMobile: "true",
                                                    })
                                                    text_helper = I18n.t('global.main.form.work_all_day');
                                                }

                                                helper.text(text_helper);
                                                tootltip_button[0].classList.remove('hide-tooltip');

                                                flatpickr("#date_collect_pr", {
                                                    altInput: true,
                                                    altFormat: "d-m-Y",
                                                    dateFormat: "Y-m-d",
                                                    disable: date_disabled,
                                                    minDate: new Date().fp_incr(parseInt(sessionStorage.getItem('day_delay_rent'))),
                                                    maxDate: new Date().fp_incr(180),
                                                    locale: flatpick_lang
                                                });

                                            }
                                        );

                                    })
                            }
                        );
                    })


                }
            });


        date_collect_pr.on('change', (e) => {


            //VERIFICAR TEMPORADA
            verify_season(e.target.value).then(
                vs => {
                    $('#date_delivery_pr').prop('disabled', false);
                    $('#time_delivery_pr').prop('disabled', false);
                    let max_date = 0;
                    let min_date = 0;

                    if(vs.length > 0){
                        max_date = parseInt(vs[0].max) + 1;
                        min_date = parseInt(vs[0].min) +1;
                    }else{
                        max_date = parseInt(sessionStorage.getItem('day_max_rent')) + 1;
                        min_date = parseInt(sessionStorage.getItem('day_min_rent')) +1;
                    }

                    flatpickr("#date_delivery_pr", {
                        altInput: true,
                        altFormat: "d-m-Y",
                        dateFormat: "Y-m-d",
                        disableMobile: "true",
                        disable: date_disabled,
                        minDate: new Date(e.target.value).fp_incr(min_date),
                        maxDate: new Date(e.target.value).fp_incr(max_date),
                        locale: flatpick_lang
                    })
                }
            );
        })

    }

    connect() {

    }


}